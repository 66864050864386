import React from 'react';
import { Container, Box } from '@mui/material';
import Header from './Header/Header.js';
import Footer from './Footer/Footer.js';

const Layout = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Container maxWidth={false} sx={{ flex: 1, padding: 0 }} disableGutters>
        {children}
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;
