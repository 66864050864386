import ReactGA from 'react-ga4';

export const trackPageview = ({ url, name }) => {
  ReactGA.send({
    hitType: 'pageview',
    page: url,
    title: name
  });
};

export const sendEvent = (category, action, value) => {
  ReactGA.event({
    category: category,
    action: action,
    value: value
  });
};

export const GOOGLE_ANALYTICS_LABELS = {
  home: { url: '/', name: 'Home' },
  services: { url: '/services', name: 'Services' },
  blogs: { url: '/blogs', name: 'Blogs_List' },
  blog: { url: '/blogs/:id', name: 'Blog_Detail' },
  plans: { url: '/plans', name: 'Plans' },
  wizardStep1: { url: '/plan/:id', name: 'Wizard_Step1' },
  wizardStep2: { url: '/plan/:id', name: 'Wizard_Step2' },
  wizardStep3: { url: '/plan/:id', name: 'Wizard_Step3' },
  wizardStep4: { url: '/plan/:id', name: 'Wizard_Step4' },
  aboutUs: { url: '/about-us', name: 'About_Us' },
  whyChoose: { url: '/why-choose', name: 'Why_Choose' },
  faq: { url: '/faq', name: 'Faq' },
  contactUs: { url: '/contact-us', name: 'Contact_Us' },
  login: { url: '/login', name: 'Login' },
  forgotPassword: { url: '/forgot-password', name: 'Forgot_Password' },
  resetPassword: { url: '/reset-password', name: 'Reset_Password' },
  profile: { url: '/profile', name: 'Profile' },
  coverageLetter: { url: '/coverage-letter', name: 'Coverage_Letter' },
  travelInformation: { url: '/travel-information', name: 'Travel_Information' },
  terms: { url: '/terms', name: 'Terms' },
  privacy: { url: '/privacy', name: 'Privacy' },
  extendedTravelProgram: {
    url: '/services/extended-travel-program',
    name: 'Extended_Travel_Program'
  },
  servicesMobileApp: { url: '/services/mobile-app', name: 'Services_Mobile_App' },
  servicesPetAssistance: { url: '/services/pet-assistance', name: 'Services_Pet_Assistance' },
  servicesJ1Visa: { url: '/services/j1-visa', name: 'Services_J1_Visa' },
  welcome: { url: '/welcome', name: 'Welcome' },
  notFound: { url: '/not-found', name: 'Not Found' },
  error: { url: '/error', name: 'Server Error' }
};
