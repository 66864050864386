// libs
import React from 'react';

// mui
import { FormControl, FormHelperText, InputLabel, Select as MuiSelect } from '@mui/material';

export function Select(props) {
  const {
    errors,
    required,
    label,
    onChange,
    value,
    name,
    formControlProps,
    hasExternalError,
    helperText,
    ...otherProps
  } = props;

  return (
    <FormControl
      fullWidth
      variant="filled"
      required={required}
      error={errors || hasExternalError}
      {...formControlProps}>
      <InputLabel id={`${label}-label`} shrink={true}>
        {label}
      </InputLabel>
      <MuiSelect
        placeholder={label}
        label={label}
        onChange={onChange}
        disableUnderline
        value={value}
        name={name}
        sx={{
          '& .MuiSelect-select .notranslate::after': label
            ? {
                content: `"${label}"`,
                opacity: 0.4
              }
            : {}
        }}
        {...otherProps}>
        {props.children}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
