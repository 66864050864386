import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';

const useTrackScrollDepth = (screenName) => {
  const scrollPositionRef = useRef(window.scrollY);

  const handleScroll = () => {
    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight
    );

    const windowHeight = window.innerHeight;

    const scrollDepth = Math.floor(
      (scrollPositionRef.current / (documentHeight - windowHeight)) * 100
    );

    scrollPositionRef.current = window.scrollY;

    if (scrollDepth >= 25 && scrollDepth < 50) {
      ReactGA.event({
        category: 'Scroll Depth',
        action: '25% Scroll Reached',
        label: screenName,
        value: 25
      });
    }

    if (scrollDepth >= 50 && scrollDepth < 75) {
      ReactGA.event({
        category: 'Scroll Depth',
        action: '50% Scroll Reached',
        label: screenName,
        value: 50
      });
    }

    if (scrollDepth >= 75) {
      ReactGA.event({
        category: 'Scroll Depth',
        action: '75% Scroll Reached',
        label: screenName,
        value: 75
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
};

export default useTrackScrollDepth;
