// libs
import React from 'react';

// mui
import { TextField } from '@mui/material';

export function TextInput(props) {
  const { name, label, placeholder = label, maxLength } = props;

  return (
    <TextField
      {...props}
      fullWidth
      name={name}
      placeholder={placeholder}
      variant="filled"
      required={props.required}
      InputProps={{ disableUnderline: true, ...props.InputProps }}
      inputProps={{ maxLength: maxLength ?? 30, ...props.inputProps }}
      InputLabelProps={{ shrink: true }}
    />
  );
}
