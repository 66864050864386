import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { decode, encode } from 'base-64';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/react';

if (!window.btoa) {
  window.btoa = encode;
}

if (!window.atob) {
  window.atob = decode;
}

Sentry.init({
  dsn: 'https://c90340180973ee544a5bd6d29d5f3ed7@o4508086420963328.ingest.de.sentry.io/4508086422667344',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/yourserver\.io\/api/,
    'https://www.globaltravelplus.com',
    'https://dev-gtp-web.cmeoffshore.com/'
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <>
    <ToastContainer
      position="bottom-right"
      autoClose={500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
    <App />
  </>,
  document.getElementById('root')
);
