import {
  SESSION_STORAGE_ACCESS_TOKEN,
  SESSION_STORAGE_EMAIL,
  SESSION_STORAGE_FIRSTNAME,
  SESSION_STORAGE_INITIALS,
  SESSION_STORAGE_IS_LOGGED_IN,
  SESSION_STORAGE_LASTNAME,
  SESSION_STORAGE_PLAN_ID,
  SESSION_STORAGE_REFRESH_TOKEN
} from './constants.js';

export const checkSessionStorageItem = (itemKey) => {
  const item = sessionStorage.getItem(itemKey);
  const hasItem = !!item;

  return [item, hasItem];
};

export const clearUserInfo = () => {
  sessionStorage.removeItem(SESSION_STORAGE_IS_LOGGED_IN);
  sessionStorage.removeItem(SESSION_STORAGE_FIRSTNAME);
  sessionStorage.removeItem(SESSION_STORAGE_LASTNAME);
  sessionStorage.removeItem(SESSION_STORAGE_ACCESS_TOKEN);
  sessionStorage.removeItem(SESSION_STORAGE_REFRESH_TOKEN);
  sessionStorage.removeItem(SESSION_STORAGE_EMAIL);
  sessionStorage.removeItem(SESSION_STORAGE_INITIALS);
  sessionStorage.removeItem(SESSION_STORAGE_PLAN_ID);
};
