import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';
import { blogsList } from './APIRequests.js';
import Blog from '../../components/Blog.js';
import PageLoader from '../../components/PageLoader.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';

export default function Blogs() {
  const { data, isLoading, error } = useQuery('blogsList', blogsList);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.blogs.name);

  return (
    <>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid pt={12} align="center">
            <Typography variant="heading1">Travel Blog</Typography>

            <Grid container py={5} align="left" columnSpacing={{ md: 3 }} rowSpacing={{ xs: 3 }}>
              {data?.map((blog) => {
                const { id, title, image, body, author, date } = blog;

                return (
                  <Grid key={id} item md={4} xs={12} align="left">
                    <Blog
                      id={id}
                      image={process.env.REACT_APP_BACKEND_URL + image}
                      title={title}
                      description={body}
                      author={author}
                      date={date}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
