import axios from "axios";

export const services = async () => {
  const response = await axios.get("/static_page/services");

  return response?.data;
};

export const extendedTravelProgram = async () => {
  const response = await axios.get("/static_page/extended_travel_program");

  return response?.data;
};

export const visaServices = async () => {
  const response = await axios.get("/static_page/j1_visa_services");

  return response?.data;
};

export const petAssistance = async () => {
  const response = await axios.get("/static_page/pet_assistance");

  return response?.data;
};

export const mobileApp = async () => {
  const response = await axios.get("/static_page/global_travel_plus_mobile");
  
  return response?.data;
};
