import React from 'react';
import { Navigate } from 'react-router-dom';
import { SESSION_STORAGE_IS_LOGGED_IN } from '../utils/constants';

const RedirectIfLoggedIn = ({ children }) => {
  const currentUser = sessionStorage.getItem(SESSION_STORAGE_IS_LOGGED_IN);
  
  if (currentUser) {
    return <Navigate to="/profile" />;
  }

  return children;
};

export default RedirectIfLoggedIn;