import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { getForgotPasswordDetails, updatePassword } from './APIRequests.js';
import PageLoader from '../../components/PageLoader.js';
import CustomAlert from '../../components/CustomAlert.js';
import CheckIcon from '@mui/icons-material/Check';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TextInput } from '../../components/form/TextField';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import {
  SESSION_STORAGE_ACCESS_TOKEN,
  SESSION_STORAGE_IS_LOGGED_IN,
  SESSION_STORAGE_REFRESH_TOKEN
} from '../../utils/constants.js';
import ServerError from '../Static/ServerError.js';

export default function ResetPassword() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data, isLoading, error } = useQuery('getResetPasswordDetails', getForgotPasswordDetails);
  const [isAlertOpened, setIsAlertOpened] = useState(false);
  const navigate = useNavigate();

  const [alertContent, setAlertContent] = useState({
    severity: '',
    title: '',
    body: ''
  });

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.resetPassword.name);

  const checkIconStyles = {
    color: 'white',
    backgroundColor: 'rgba(232, 242, 238, 0.3)',
    borderRadius: '50%'
  };

  const titleStyles = {
    fontFamily: 'Urbanist',
    fontSize: isMobile ? '35px' : '40px',
    fontWeight: isMobile ? 600 : 800,
    lineHeight: '48px'
  };

  const subTitleStyles = {
    fontFamily: 'Inter',
    fontSize: isMobile ? '16px' : '20px',
    fontWeight: isMobile ? 400 : 600,
    lineHeight: '48px'
  };

  const mainContainerStyles = {
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    marginBottom: '14px'
  };

  const imageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    width: '100%',
    height: '600px',
    position: 'relative',
    borderRadius: '10px',
    display: { xs: 'none', md: 'flex' }
  });

  const textBoxStyles = {
    position: 'absolute',
    bottom: '40px',
    color: 'white',
    left: '40px',
    width: '50%'
  };

  const formBoxStyles = {
    width: isMobile ? '100%' : '70%'
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('New Password is required')
      .min(10, 'Password must be at least 10 characters long')
      .matches(/[a-zA-Z]/, 'Password must contain at least one letter')
      .matches(/\d/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm New Password is required')
  });

  return (
    <>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid container columnSpacing={15} pt={12} pb={6}>
            <Grid item xs={12} md={6}>
              <Box sx={imageBoxStyles(process.env.REACT_APP_BACKEND_URL + data?.image)}>
                <Box sx={textBoxStyles}>
                  {data?.elements.map((element, index) => (
                    <Grid key={index} container sx={mainContainerStyles}>
                      <Grid item>
                        <CheckIcon sx={checkIconStyles} />
                      </Grid>
                      <Grid item ml={2} display={'flex'} flexDirection={'column'}>
                        <Typography color="white" fontWeight={'200'} variant="body">
                          {element}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomAlert
                severity={alertContent.severity}
                title={alertContent.title}
                body={alertContent.body}
                isOpened={isAlertOpened}
                onAlertClose={() => setIsAlertOpened(false)}
              />
              <Box sx={formBoxStyles}>
                <Typography sx={titleStyles} paragraph={true}>
                  Reset password
                </Typography>
                <Typography sx={subTitleStyles} paragraph={true}>
                  My Global Travel Plus
                </Typography>
                <Typography variant="body" mb={2} paragraph={true}>
                  Reset your password:
                </Typography>
                <Formik
                  initialValues={{
                    password: '',
                    confirmPassword: ''
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    const { password } = values;
                    const body = { new_password: password };

                    updatePassword(body)
                      .then((data) => {
                        setSubmitting(false);
                        resetForm();
                        sessionStorage.setItem(SESSION_STORAGE_IS_LOGGED_IN, true);
                        sessionStorage.setItem(SESSION_STORAGE_ACCESS_TOKEN, data.access_token);
                        sessionStorage.setItem(SESSION_STORAGE_REFRESH_TOKEN, data.refresh_token);

                        navigate('/profile', {
                          state: {
                            message: 'Password reset successful!'
                          }
                        });
                      })
                      .catch((error) => {
                        setSubmitting(false);

                        setAlertContent({
                          severity: 'error',
                          title: 'Failed to reset password',
                          body: 'Please try again to reset your password'
                        });

                        setIsAlertOpened(true);
                      });
                  }}>
                  {({ isSubmitting, errors, touched, values, handleChange, handleBlur }) => {
                    const confirmPasswordEndIcon = isSubmitting ? (
                      <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />
                    ) : null;

                    return (
                      <Form>
                        <Fragment>
                          <TextInput
                            htmlFor="Password"
                            name="password"
                            label="New Password"
                            title="New Password"
                            variant="filled"
                            type="password"
                            error={touched?.password && Boolean(errors?.password)}
                            helperText={touched?.password && errors?.password}
                            sx={{ marginBottom: 3 }}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <TextInput
                            htmlFor="Confirm Password"
                            name="confirmPassword"
                            label="Confirm New Password"
                            title="Confirm New Password"
                            type="password"
                            variant="filled"
                            error={touched?.confirmPassword && Boolean(errors?.confirmPassword)}
                            helperText={touched?.confirmPassword && errors?.confirmPassword}
                            sx={{ marginBottom: 3 }}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Button
                            fullWidth={true}
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                            endIcon={confirmPasswordEndIcon}>
                            Confirm Password
                          </Button>
                        </Fragment>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
