import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Collapse from "@mui/material/Collapse";

const CustomAlert = (props) => {
  const { severity, title, body, isOpened, onAlertClose } = props;

  const alertStyles = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    color: "#181818",
    marginBottom: 3,
  };
  
  const titleStyles = {
    fontSize: "16px",
    fontWeight: 600,
  };

  return (
    <Collapse in={isOpened}>
      <Alert
        sx={alertStyles}
        severity={severity}
        iconMapping={{
          success: <CheckCircleOutlineIcon fontSize="inherit" />,
        }}
        onClose={onAlertClose}
      >
        <AlertTitle sx={titleStyles}>{title}</AlertTitle>
        {body}
      </Alert>
    </Collapse>
  );
};

export default CustomAlert;