import { Grid, Typography, Container, Button } from '@mui/material';
import React from 'react';
import ErrorIcon from '../../assets/error.svg';

import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';

export default function ServerError() {
  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.error.name);

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Grid container pt={20} pb={15} alignItems="center" direction="column" textAlign="center">
        <Grid item>
          <img src={ErrorIcon} alt="Server Error Icon" style={{ marginBottom: 16 }} />
        </Grid>
        <Grid item>
          <Typography variant="heading2" paragraph>
            Something Went Wrong
          </Typography>
          <Typography
            mt={3}
            variant="body"
            textAlign={'center'}
            paragraph
            dangerouslySetInnerHTML={{
              __html:
                "Oops! Something went wrong on our end. We're working to fix it. Please try again later."
            }}
          />
          <Button variant="contained" href="/" sx={{ mt: 5 }}>
            Back To Homepage
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
