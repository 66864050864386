import React from "react";
import { Box } from "@mui/material";

const YouTubeVideo = ({ videoUrl }) => {
  // Extract the video ID from the YouTube URL
  const videoId = videoUrl.split("v=")[1].split("&")[0];

  const parentBoxStyles = {
    position: "relative",
    paddingBottom: "56.25%", // 16:9 aspect ratio
    height: 0,
    overflow: "hidden",
  };
  
  const childBoxStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0,
  };

  return (
    <Box sx={parentBoxStyles}>
      <Box
        component="iframe"
        sx={childBoxStyles}
        src={`https://www.youtube.com/embed/${videoId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video"
      />
    </Box>
  );
};

export default YouTubeVideo;
