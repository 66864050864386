import moment from 'moment';

export function calculateAgeLimit(maxAge, minAge) {
  const minBirthDate = moment().subtract(maxAge, 'years');
  const maxBirthDate = moment().subtract(minAge, 'years');

  return { minBirthDate, maxBirthDate };
}

export const extractIds = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (Array.isArray(obj[key])) {
      // Handle arrays of objects
      acc[key] = obj[key].map((item) => {
        if (typeof item === 'object' && item !== null) {
          return extractIds(item);
        } else {
          return item;
        }
      });
    } else if (typeof obj[key] === 'object' && obj[key] !== null && obj[key].id !== undefined) {
      acc[key] = obj[key].id;
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      acc[key] = extractIds(obj[key]);
    } else {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

export const calculateAge = (dateOfBirth) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};
