import * as React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Homepage from './pages/Homepage/Homepage.js';
import Faq from './pages/Static/Faq.js';
import About from './pages/About/About.js';
import Blogs from './pages/Blogs/Blogs.js';
import Blog from './pages/Blogs/Blog.js';
import Services from './pages/Services/Services.js';
import Plans from './pages/Plans/Plans.js';
import Wizard from './pages/Wizard/Wizard.js';
import WelcomePage from './pages/WelcomePage/WelcomePage.js';
import Login from './pages/Account/Login.js';
import Profile from './pages/Account/Profile.js';
import CoverageLetter from './pages/Account/CoverageLetter.js';
import TravelInformation from './pages/Account/TravelInformation.js';
import ForgotPassword from './pages/Account/ForgotPassword.js';
import ResetPassword from './pages/Account/ResetPassword.js';

import Terms from './pages/Static/Terms.js';
import Privacy from './pages/Static/Privacy.js';
import NotFound from './pages/Static/NotFound.js';
import ServerError from './pages/Static/ServerError.js';
import WhyChoose from './pages/About/WhyChoose.js';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import './axiosConfig';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { COLORS } from './utils/colors.js';

import ExtendedTravelProgram from './pages/Services/ExtendedTravelProgram.js';
import MobileApp from './pages/Services/MobileApp.js';
import PetAssistance from './pages/Services/PetAssistance.js';
import VisaServices from './pages/Services/VisaServices.js';
import ContactUs from './pages/Contact/ContactUs.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import RedirectIfLoggedIn from './components/RedirectIfLoggedIn.js';
import Layout from './layout/Layout';
import RedirectIfGuest from './components/RedirectIfGuest.js';

import {
  GLOBAL_TRAVEL_PLUS,
  PLAN_FILL_MODE,
  PLAN_RENEW_MODE,
  PLAN_UPADTE_MODE,
  QUERY_PARAM_URL,
  SESSION_STORAGE_CLIENT,
  getTitleForPath
} from './utils/constants.js';
import { decode, encode } from 'base-64';
import ReactGA from 'react-ga4';

if (!window.btoa) {
  window.btoa = encode;
}

if (!window.atob) {
  window.atob = decode;
}

export default function App() {
  ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID, { send_page_view: false });
  const queryClient = new QueryClient();

  let theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 1024,
        lg: 1200,
        xl: 1400
      }
    }
  });

  theme = createTheme(theme, {
    palette: {
      primary: {
        main: '#5CB5A2',
        light: '#5CB5A20F'
      },
      secondary: {
        main: '#FFC27A'
      },
      custom1: {
        main: '#10182F',
        border: '#CFD3D4'
      },
      error: {
        main: COLORS.error
      }
    },
    typography: {
      heading1: {
        fontFamily: 'Urbanist',
        fontWeight: '800',
        fontSize: '58px',
        color: '#10182F',
        '& p': {
          margin: '8px 0'
        },
        [theme.breakpoints.down('lg')]: {
          fontSize: '50px'
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '45px'
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '35px'
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '40px'
        }
      },
      heading2: {
        fontFamily: 'Urbanist',
        fontWeight: '800',
        lineHeight: '48px',
        fontSize: '40px',
        color: '#10182F',
        '& p': {
          margin: '8px 0'
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '30px'
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '25px',
          marginBottom: 0,
          lineHeight: '30px'
        }
      },
      heading3: {
        fontFamily: 'Urbanist',
        fontWeight: '700',
        lineHeight: '50px',
        fontSize: '50px',
        color: '#10182F',
        '& p': {
          margin: '8px 0'
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '35px',
          lineHeight: '40px'
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '25px',
          marginBottom: 0,
          lineHeight: '27px'
        }
      },
      heading4: {
        fontFamily: 'Urbanist',
        fontWeight: '600',
        lineHeight: '42px',
        fontSize: '35px',
        color: '#10182F',
        '& p': {
          margin: '8px 0'
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '20px',
          lineHeight: '30px'
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '17px',
          marginBottom: 0,
          lineHeight: '28px'
        }
      },
      subtitle: {
        fontFamily: 'Urbanist',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '27px',
        color: '#5A6475',
        width: '60%',
        '& p': {
          margin: '8px 0'
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '16px',
          lineHeight: '24px',
          width: '100%'
        }
      },
      headerDescription: {
        fontFamily: 'Inter',
        fontWeight: '600',
        fontSize: '23px',
        lineHeight: '27px',
        width: '60%',
        '& p': {
          margin: '8px 0'
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '20px',
          lineHeight: '22px',
          width: '100%',
          margin: 0
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
          lineHeight: '20px',
          width: '100%',
          margin: 0
        }
      },
      bodyTitle: {
        fontFamily: 'Inter',
        fontSize: '19px',
        fontWeight: '500',
        lineHeight: '24px',
        color: '#10182F',
        '& p': {
          margin: '8px 0'
        },
        '& a': {
          color: '#5CB5A2'
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '18px',
          lineHeight: '22px',
          margin: 0
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '16px',
          lineHeight: '22px',
          margin: 0
        }
      },
      body: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#5A6475',
        '& p': {
          margin: '8px 0'
        },
        '& a': {
          color: '#5CB5A2'
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '15px',
          lineHeight: '22px',
          margin: 0
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '15px',
          lineHeight: '22px',
          margin: 0
        }
      },
      bodyNoJustify: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#5A6475',
        '& p': {
          margin: '8px 0'
        },
        '& a': {
          color: '#5CB5A2'
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '15px',
          lineHeight: '22px',
          margin: 0
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '15px',
          lineHeight: '22px',
          margin: 0
        }
      },
      smallText: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '24px',
        color: '#1A191E80',
        '& p': {
          margin: '8px 0'
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '13px',
          lineHeight: '18px',
          margin: 0
        }
      },
      footer_title: {
        fontFamily: 'Urbanist',
        fontWeight: '700',
        fontSize: '14px',
        color: '#A2AED2',
        lineHeight: '20px'
      },
      static_content: {
        color: '#5A6475',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        textTransform: 'none',
        fontFamily: 'Inter',
        '& strong': {
          color: '#10182F'
        },
        '& a': {
          color: '#5CB5A2',
          fontWeight: '600'
        }
      },
      more_info_text: {
        fontFamily: 'Urbanist',
        fontSize: '30px',
        fontWeight: 300,
        marginTop: '0',
        [theme.breakpoints.down('md')]: {
          fontSize: '25px',
          fontWeight: 600,
          marginTop: '40px'
        }
      }
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          },
          contained: {
            borderRadius: '8px',
            color: '#fff',
            textTransform: 'none',
            fontSize: '16px',
            padding: '12px 24px',
            fontFamily: 'Urbanist',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: '#52a391'
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '14px',
              padding: '8px 18px'
            }
          },
          outlined: {
            borderRadius: '8px',
            fontSize: '16px',
            padding: '12px 24px',
            fontFamily: 'Urbanist',
            textTransform: 'none',
            '&:hover': {
              color: ''
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '14px',
              padding: '8px 18px'
            }
          },
          text: {
            fontFamily: 'Inter',
            padding: 0,
            marginTop: '8px',
            '&:hover': {
              backgroundColor: 'unset'
            }
          }
        },
        variants: [
          {
            props: { variant: 'menuButton' },
            style: {
              color: 'black',
              fontSize: '16px',
              textTransform: 'none',
              fontFamily: 'Urbanist',
              '.MuiButton-endIcon': { marginLeft: 0 }
            }
          }
        ]
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: '8px 0'
          }
        }
      },

      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: '24px',
            paddingRight: '24px'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          filled: {
            '&.MuiInputBase-input': {
              backgroundColor: 'transparent'
            }
          },
          root: {
            [theme.breakpoints.down('sm')]: {
              fontSize: '12px'
            },

            '&.Mui-focused': {
              border: `1px solid #BEC2C3`,
              backgroundColor: '#F2F2F2'
            },
            '&.MuiFilledInput-root': {
              backgroundColor: 'white',
              '&.Mui-focused': {
                backgroundColor: '#F2F2F2',
                border: `1px solid #A8B2B6`
              },
              height: '58px',
              borderRadius: 8,
              border: '1px solid #CFD3D4',
              color: 'black',
              '& fieldset': {
                color: 'black'
              },
              '&:hover': {
                backgroundColor: '#F5F5F5',
                border: `1px solid #A8B2B6`
              },
              '&.Mui-focused fieldset': {
                backgroundColor: '#F2F2F2',
                border: `1px solid #A8B2B6`
              },
              '&.Mui-focused .MuiFilledInput-notchedOutline': {
                backgroundColor: '#F2F2F2',
                border: `1px solid #A8B2B6`
              },
              '&.Mui-disabled': {
                color: '#5E6366',
                backgroundColor: '#F2F4F5'
              }
            }
          }
        },
        defaultProps: {
          IconComponent: KeyboardArrowDownIcon
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              color: `#333333`
            },
            overflowWrap: 'unset',
            fontFamily: 'Inter',
            fontSize: '15px',
            fontWeight: '500',
            color: 'black',
            whiteSpace: 'inherit',
            [theme.breakpoints.down('md')]: {
              fontSize: '13.5px'
            }
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: 'Inter',
            '& .MuiFilledInput-root': {
              color: '#10182F',
              fontFamily: 'Inter',
              border: '1px solid #CFD3D4',
              backgroundColor: 'white',
              borderRadius: '8px',
              [theme.breakpoints.down('sm')]: {
                fontSize: '12px'
              },
              '&.Mui-focused': {
                backgroundColor: '#F2F2F2',
                border: `1px solid ##A8B2B6 !important`,
                borderRadius: '8px'
              },
              '&:hover': {
                backgroundColor: '#F5F5F5',
                border: `1px solid #BEC2C3 !important`,
                borderRadius: '8px'
              },
              '&.Mui-disabled': {
                color: '#5E6366',
                backgroundColor: '#F2F4F5'
              }
            },
            '& .MuiInputLabel-filled': {
              [theme.breakpoints.down('sm')]: {
                fontSize: '12px'
              },
              //this the styles of the label
              color: '#10182F',
              fontFamily: 'Inter',
              fontWeight: 500,
              borderRadius: '8px',
              '&.Mui-focused': {
                color: `#333333`
              }
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            height: '58px',
            border: '1px solid #CFD3D4',
            '&.Mui-focused fieldset': {
              border: 0
            },
            '&.Mui-focused': {
              border: `1px solid #A8B2B6 !important`,
              backgroundColor: '#F2F2F2'
            },
            '& fieldset': {
              border: '0'
            },
            '& .MuiInputBase-input.Mui-disabled': {
              backgroundClip: 'text'
            },
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              backgroundClip: 'text'
            },

            [theme.breakpoints.up('xl')]: {
              height: '58px'
            },

            [theme.breakpoints.between('lg', 'xl')]: {
              height: '58px'
            },

            [theme.breakpoints.between('md', 'lg')]: {
              height: '58px'
            },

            [theme.breakpoints.between('sm', 'md')]: {
              height: '58px'
            },

            [theme.breakpoints.down('sm')]: {
              height: '58px'
            }
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: '0px',
            border: 'none'
          }
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              backgroundClip: 'text'
            }
          }
        }
      },
      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            '&.Mui-selected': {
              backgroundColor: '#5CB5A2',
              color: 'white',
              '&:focus, &:hover': {
                backgroundColor: '#5CB5A2',
                color: 'white'
              }
            }
          }
        }
      },
      MuiPickersMonth: {
        styleOverrides: {
          monthButton: {
            '&.Mui-selected': {
              backgroundColor: '#5CB5A2',
              color: 'white',
              '&:focus, &:hover': {
                backgroundColor: '#5CB5A2',
                color: 'white'
              }
            }
          }
        }
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#5CB5A2',
              color: 'white',
              '&:focus, &:hover': {
                backgroundColor: '#5CB5A2',
                color: 'white'
              }
            }
          },
          today: {
            backgroundColor: '#white',
            color: 'black',
            borderColor: 'white',
            '&:focus, &:hover': {
              backgroundColor: '#5CB5A2',
              color: 'white',
              borderColor: 'white'
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '& .Mui-error': {},
            color: COLORS.error,
            fontFamily: 'Inter',
            fontSize: '14px',
            marginLeft: '14px'
          }
        }
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            '&.MuiButtonBase-root.MuiStepButton-root': {
              padding: '24px 16px',
              margin: '-24px -16px'
            }
          }
        }
      },
      MuiFormLabel: {
        root: {
          fontSize: '14px'
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#5CB5A2',
            fontWeight: '500',
            fontFamily: 'Inter',
            fontSize: '16px',
            lineHeight: '24px',
            '& p': {
              margin: '8px 0'
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '15px',
              lineHeight: '22px',
              margin: 0
            }
          }
        }
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            borderRadius: '8px',
            borderWidth: '1px',
            color: '#E0E0E0',
            fontFamily: 'Inter',
            '&.Mui-checked': {
              color: '#5CB5A2'
            }
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: '4px',
            padding: '17px',
            backgroundColor: '#F0F0F0',
            color: COLORS.black,
            height: '52px',
            fontSize: '16px',
            lineHeight: '19.36px',
            fontFamily: 'Inter'
          },
          deleteIcon: {
            marginLeft: '15px'
          }
        }
      }
    }
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Router>
            <Layout>
              <AppRoutes />
            </Layout>
          </Router>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}


if (!window.btoa) {
  window.btoa = encode;
}

if (!window.atob) {
  window.atob = decode;
}

function AppRoutes() {
  if (!window.btoa) {
    window.btoa = encode;
  }

  if (!window.atob) {
    window.atob = decode;
  }

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const clientName = queryParams.get(QUERY_PARAM_URL);

  const currentRoute = location.pathname;

  const documentTitle = getTitleForPath(currentRoute);

  React.useEffect(() => {
    if (clientName) sessionStorage.setItem(SESSION_STORAGE_CLIENT, clientName);
  }, [clientName]);

  React.useEffect(() => {
    document.title = documentTitle || GLOBAL_TRAVEL_PLUS;
  }, [documentTitle]);

  return (
    <Routes>
      <Route element={<Homepage />} path="/" />
      <Route element={<Homepage />} path="/:client" />
      <Route element={<Services />} path="/services" />
      <Route element={<Blogs />} path="/blogs" />
      <Route element={<Blog />} path="/blogs/:id" />
      <Route
        path="/plans"
        element={
          <RedirectIfGuest>
            <Plans />
          </RedirectIfGuest>
        }
      />
      <Route
        path="/:client/plans"
        element={
          <RedirectIfGuest>
            <Plans />
          </RedirectIfGuest>
        }
      />
      <Route
        path="/plan/:id"
        element={
          <RedirectIfGuest>
            <Wizard mode={PLAN_FILL_MODE} />
          </RedirectIfGuest>
        }
      />
      <Route
        path="/:client/plan/:id"
        element={
          <RedirectIfGuest>
            <Wizard mode={PLAN_FILL_MODE} />
          </RedirectIfGuest>
        }
      />

      <Route element={<About />} path="/about-us" />
      <Route element={<WhyChoose />} path="/why-choose" />
      <Route element={<Faq />} path="/faq" />
      <Route element={<ContactUs />} path="/contact-us" />
      <Route
        path="/login"
        element={
          <RedirectIfLoggedIn>
            <Login />
          </RedirectIfLoggedIn>
        }
      />
      <Route element={<ForgotPassword />} path="/forgot-password/:token" />

      <Route element={<ResetPassword />} path="/reset-password" />

      <Route
        path="/reset-password"
        element={
          <ProtectedRoute>
            <ResetPassword />
          </ProtectedRoute>
        }
      />

      <Route element={<ResetPassword />} path="/reset-password" />

      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/coverage-letter"
        element={
          <ProtectedRoute>
            <CoverageLetter />
          </ProtectedRoute>
        }
      />
      <Route
        path="/travel-information"
        element={
          <ProtectedRoute>
            <TravelInformation />
          </ProtectedRoute>
        }
      />

      <Route
        element={
          <ProtectedRoute>
            <Wizard mode={PLAN_UPADTE_MODE} />
          </ProtectedRoute>
        }
        path="/plan/:id/update"
      />
      <Route
        element={
          <ProtectedRoute>
            <Wizard mode={PLAN_UPADTE_MODE} />
          </ProtectedRoute>
        }
        path="/:client/plan/:id/update"
      />
      <Route
        element={
          <ProtectedRoute>
            <Wizard mode={PLAN_RENEW_MODE} />
          </ProtectedRoute>
        }
        path="/plan/:id/renew"
      />
      <Route
        element={
          <ProtectedRoute>
            <Wizard mode={PLAN_RENEW_MODE} />
          </ProtectedRoute>
        }
        path="/:client/plan/:id/renew"
      />
      <Route element={<Terms />} path="/terms" />
      <Route element={<Privacy />} path="/privacy" />
      <Route element={<ExtendedTravelProgram />} path="/services/extended-travel-program" />
      <Route element={<MobileApp />} path="/services/mobile-app" />
      <Route element={<PetAssistance />} path="/services/pet-assistance" />
      <Route element={<VisaServices />} path="/services/j1-visa" />
      <Route element={<WelcomePage />} path="/welcome/:token" />
      <Route element={<NotFound />} path="/pages/not-found" />
      <Route element={<ServerError />} path="/pages/error" />

      <Route path="*" element={<Navigate to="/pages/not-found" />} />
    </Routes>
  );
}
