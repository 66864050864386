import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';

const LottieAnimation = ({animationData, looping}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (animationData) {
      const anim = lottie.loadAnimation({
        container: containerRef.current,
        animationData: animationData,
        renderer: 'svg',
        loop: looping,
        autoplay: true,
      });

      return () => {
        anim.destroy();
      };
    }
  }, []);

  return <div ref={containerRef} />;
};

export default LottieAnimation;
