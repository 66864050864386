import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';

const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isMobile'
})(({ isMobile }) => ({
  margin: '64px 0',
  padding: isMobile ? '24px 36px' : '24px 64px',
  backgroundColor: '#5BB5A2',
  color: '#fff',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: isMobile ? 'block' : 'flex',
  borderRadius: '8px'
}));

export default StyledGrid;
