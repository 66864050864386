import { Button, Grid, Step, StepConnector, StepLabel, stepConnectorClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StepStyled = styled(Step)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    padding: 0
  }
}));

export const StepLabelStyled = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#10182F !important',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      width: '250px'
    }
  },
  '& .MuiStepLabel-label.Mui-active': {
    color: '#10182F !important',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '700',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      width: '250px'
    }
  },
  '& .MuiStepLabel-label.Mui-completed': {
    color: '#10182F !important',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      width: '250px'
    }
  }
}));

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    [theme.breakpoints.up('md')]: {
      top: 38
    },
    top: 24
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.custom1.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,

    [theme.breakpoints.up('md')]: {
      marginLeft: 30,
      marginRight: 30
    },
    marginLeft: 10,
    marginRight: 10
  }
}));

export const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: 'transparent',
  color: '#fff',
  border: '1px solid #E1E4EC',
  width: 45,
  height: 45,
  [theme.breakpoints.up('md')]: {
    width: 75,
    height: 75
  },
  borderWidth: '2px',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderWidth: '2px'
  }),
  ...(ownerState.completed && {
    borderWidth: '2px',
    borderColor: theme.palette.primary.main,
    backgroundColor: 'white'
  })
}));

export const CenteredColumnContainer = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column'
}));

export const AccordionContainer = styled(Grid)(() => ({
  marginBottom: '30px',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column'
}));

export const StepperContainer = styled(Grid)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: 1
}));

export const ShowAccordionButton = styled(Button)(() => ({
  width: '250px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: 0,
  margin: 0,
  '&:focus': {
    backgroundColor: 'white'
  },
  color: '#10182F',
  fontFamily: 'Inter',
  fontWeight: 'bold',
  fontSize: '21px',
  lineHeight: '32px'
}));

export const AddChildButtonStyles = styled(Button)(() => ({
  fontWeight: '600',
  textDecoration: 'underline',
  marginTop: 3,
  marginBottom: 2.5
}));

export const ApplyButtonStyled = styled(Button)(() => ({
  fontWeight: '500',
  fontFamily: 'Inter',
  marginBottom: 2.5,
  backgroundColor: '#10182F0D',
  color: '#10182F',
  borderRadius: '8px',
  border: '1px solid #10182FCC',
  height: '58px',
  '&:hover': {
    backgroundColor: '#10182F0D',
    color: '#10182F',
    borderRadius: '8px',
    border: '1px solid #10182FCC'
  },
  '&:focus': {
    backgroundColor: '#10182F0D',
    color: '#10182F',
    borderRadius: '8px',
    border: '1px solid #10182FCC'
  }
}));

export const CenterFormGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('xl')]: {
    width: '600px',
    marginTop: theme.spacing(7),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5)
  },
  [theme.breakpoints.only('lg')]: {
    width: '600px',
    marginTop: theme.spacing(7),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5)
  },
  [theme.breakpoints.only('md')]: {
    width: '500px',
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(1.2),
    paddingRight: theme.spacing(1.2)
  },
  [theme.breakpoints.only('sm')]: {
    width: '450px',
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(1.2),
    paddingRight: theme.spacing(1.2)
  },
  [theme.breakpoints.only('xs')]: {
    width: '370px',
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(1.2),
    paddingRight: theme.spacing(1.2)
  }
}));

export const SummaryContainer = styled(Grid)(({ theme }) => ({
  flex: 0.94,
  backgroundColor: theme.palette.primary.light,
  padding: '32px 24px'
}));

export const SummarySectionContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  border: `1px solid ${theme.palette.custom1.border}`,
  borderRadius: '8px'
}));

export const ActionButtonsContainer = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
}));
