import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import Facility from "../Facility";

export default function Features({ title, description, list }) {
  return (
    <Container maxWidth="xl">
      <Grid container pb={5} pt={{md:5, xs:10}} columnSpacing={{ md: 10 }}>
        <Grid item md={6} xs={12}>
          <Typography
            paragraph={true}
            variant="heading2"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Grid container>
            <Grid item md={10}>
              <Typography
                variant="subtitle"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12} pt={{ xs: 4, md: 0 }}>
          {list?.map((facility) => (
            <Grid key={facility.id} item>
              <Facility
                img={process.env.REACT_APP_BACKEND_URL + facility.image}
                name={facility.title}
                description={facility.text}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}
