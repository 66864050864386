import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function Feedback({ text }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: "none",
        p: isMobile ? 1.5 : 3,
        backgroundColor: "rgba(92, 181, 162, 0.06)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        width:'100%'
      }}
    >
      <Grid sx={{ fontSize: "35px", color: "#FFC27A" }}>
        <FontAwesomeIcon icon={faQuoteRight} />
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "500",
            color: "#10182F",
            '& p': {
              my: isMobile ? '0px' : '16px'
            }
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Grid>
    </Card>
  );
}

export default Feedback;
