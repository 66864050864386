import { Grid } from '@mui/material';
import React from 'react';
import animationData from '../static/loader.json';
import LottieAnimation from './LottieAnimation';

function PageLoader() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        background: '#fff',
        zIndex: '9999'
      }}>
      <Grid item width={150}>
        <LottieAnimation animationData={animationData} looping={true} />
      </Grid>
    </Grid>
  );
}

export default PageLoader;
