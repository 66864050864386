import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const textStyles = {
  fontFamily: 'Urbanist',
  fontWeight: 600,
  fontSize: '16px',
  '& p': {
    marginBottom: 0
  }
};

const ServiceContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start', // Aligns items to the start of the row
  alignItems: 'center',
  gap: '24px',
  flexWrap: 'wrap'
});

const ServiceBox = styled(Box)({
  width: '170px', // Fixed width
  height: '170px', // Fixed height to keep it square
  border: '2px solid #E8E8E8',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  position: 'relative',
  flexShrink: 0,
  cursor: 'pointer',
  '&:hover': {
    border: '2px solid #F5FBF9',
    backgroundColor: '#F5FBF9'
  }
});

const ServiceContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const OtherServices = ({ list }) => {
  const navigate = useNavigate();

  const handleNavigate = (href) => {
    navigate(href);
  };

  return (
    <ServiceContainer>
      {list.map((service) => {
        const { id, body, image, url } = service;
        
        return (
          <ServiceBox key={id} onClick={() => handleNavigate(url)}>
            <ServiceContent>
              <img src={process.env.REACT_APP_BACKEND_URL + image} alt="" />
              <Typography
                sx={textStyles}
                dangerouslySetInnerHTML={{
                  __html: body
                }}
              />
            </ServiceContent>
          </ServiceBox>
        );
      })}
    </ServiceContainer>
  );
};

export default OtherServices;
