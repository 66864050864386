import { Grid, Typography, Container, Button } from '@mui/material';
import React from 'react';
import NotFoundIcon from '../../assets/404.svg';

import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';

export default function NotFound() {
  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.notFound.name);

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Grid
        container
        pt={20}
        pb={15}
        alignItems="center"
        direction="column"
        textAlign="center"
       >
        <Grid item>
          <img src={NotFoundIcon} alt="Not Found Icon" style={{ marginBottom: 16 }} />
        </Grid>
        <Grid item>
          <Typography variant="heading2" paragraph>
            Page Not Found
          </Typography>
          <Typography
            mt={3}
            variant="body"
            textAlign={'center'}
            paragraph
            dangerouslySetInnerHTML={{
              __html: 'Sorry, the page you are looking for does not exist.'
            }}
          />
           <Button variant="contained" href="/" sx={{ mt: 5 }}>
              Back To Homepage
            </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
