import { Grid, Typography, Container, Box, Button } from '@mui/material';
import React, { Fragment } from 'react';
import { useQuery } from 'react-query';
import { about } from './APIRequests.js';
import PageLoader from '../../components/PageLoader.js';
import Service from '../../components/about/Service.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { MAX_NB_OF_OFFERS_CARDS_PER_ROW } from '../../utils/constants';
import Offer from '../../components/about/Offer.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';

export default function About() {
  const { data, isLoading, error } = useQuery('about', about);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.aboutUs.name);

  const imageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '450px',
    borderRadius: '10px'
  });

  const gradientBackgroundStyles = {
    backgroundImage: 'url("/images/about-bg.svg")',
    backgroundSize: 'cover',
    marginTop: '65px'
  };

  const textStyles = {
    fontFamily: 'Inter',
    color: '#5A6475'
  };

  return (
    <>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Fragment>
          <Grid container pt={{ md: 6, xs: 12 }} sx={!isMobile ? gradientBackgroundStyles : {}}>
            <Grid item md={12} xs={12} align="center">
              <Typography variant="heading1">{data?.title}</Typography>
            </Grid>
            <Grid item md={12}>
              <Container maxWidth="xl">
                <Grid container pt={8} columnSpacing={15}>
                  <Grid item md={8} xs={12}>
                    <Typography variant="heading2">
                      {data?.paragraphs.introduction.title}
                    </Typography>
                    <Typography
                      sx={textStyles}
                      dangerouslySetInnerHTML={{
                        __html: data?.paragraphs.introduction.body
                      }}
                    />
                  </Grid>
                  <Grid item md={4} sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Box
                      sx={imageBoxStyles(
                        process.env.REACT_APP_BACKEND_URL + data?.paragraphs.introduction.image
                      )}></Box>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
          <Container maxWidth="xl">
            <Grid pt={10} align="center">
              <Typography variant="heading2">{data?.paragraphs.what_we_do.title}</Typography>
              <Typography
                sx={textStyles}
                dangerouslySetInnerHTML={{
                  __html: data?.paragraphs.what_we_do.introduction
                }}
              />
              <Grid container py={3} columnSpacing={3} rowSpacing={3} align="left">
                {data?.paragraphs.what_we_do.services.map((service) => (
                  <Grid item md={4} xs={12} key={service.id}>
                    <Service
                      icon={process.env.REACT_APP_BACKEND_URL + service.image}
                      text={service.body}></Service>
                  </Grid>
                ))}
              </Grid>
              <Typography
                sx={textStyles}
                dangerouslySetInnerHTML={{
                  __html: data?.paragraphs.what_we_do.body
                }}
              />
              <Button variant="contained" href="/services" sx={{ mt: 5 }}>
                {data?.paragraphs.what_we_do.button_text}
              </Button>
            </Grid>
          </Container>

          <Grid mt={10} py={5} sx={{ backgroundColor: '#FAFAFA' }} align="center">
            <Container maxWidth="xl">
              <Typography
                variant="heading2"
                sx={{
                  mb: 1
                }}
                dangerouslySetInnerHTML={{
                  __html: data?.paragraphs.offers.title
                }}
              />

              <Typography
                variant="subtitle"
                paragraph={true}
                sx={{ my: 4 }}
                dangerouslySetInnerHTML={{
                  __html: data?.paragraphs.offers.body
                }}
              />
              <Grid container px={{ xs: 0, md: 5 }} pr={{ xs: 0, md: 5 }} pb={5}>
                {data?.paragraphs.offers.offers?.slice(0, 6).map((offer, index) => {
                  const showBorderBottom =
                    isMobile && index !== data?.paragraphs.offers.offers?.length - 1;

                  const showBorderTop = !isMobile && index + 1 > MAX_NB_OF_OFFERS_CARDS_PER_ROW;

                  const showBorderSideWays =
                    !isMobile && (index + 1) % MAX_NB_OF_OFFERS_CARDS_PER_ROW === 2;

                  return (
                    <Grid
                      key={offer.id}
                      item
                      md={4}
                      xs={12}
                      borderBottom={showBorderBottom ? '1px solid #E4E4E7' : 'none'}
                      borderTop={showBorderTop ? '1px solid #E4E4E7' : 'none'}
                      borderRight={showBorderSideWays ? '1px solid #E4E4E7' : 'none'}
                      borderLeft={showBorderSideWays ? '1px solid #E4E4E7' : 'none'}>
                      <Offer
                        img={process.env.REACT_APP_BACKEND_URL + offer.image}
                        body={offer.body}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Container>
          </Grid>
        </Fragment>
      )}
    </>
  );
}
