import * as React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Blog from "../Blog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function Blogs({ title, description, list, button }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Adjust breakpoint as needed
  return (
    <Grid py={5} align="center">
      <Container maxWidth="xl">
        <Typography
          variant="heading2"
          sx={{
            mb: 1,
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <Typography
          variant="subtitle"
          paragraph={true}
          sx={{ m: 2 }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Grid
          container
          px={{ md: 5, xs: 0 }}
          pb={5}
          columnSpacing={{ md: 3 }}
          rowSpacing={{ xs: 3 }}
        >
          {list?.slice(0, isMobile ? 2 : 3).map((blog) => {
            const { id, title, image, text, author, date } = blog;
            return (
              <Grid
                key={id}
                item
                md={4}
                xs={12}
                align="left"
                pt={{ md: 5, xs: 0 }}
              >
                <Blog
                  id={id}
                  image={process.env.REACT_APP_BACKEND_URL + image}
                  title={title}
                  description={text}
                  author={author}
                  date={date}
                />
              </Grid>
            );
          })}
        </Grid>
        <Button
          variant="contained"
          href="/blogs"
          sx={{
            color: "#fff",
            textTransform: "none",
          }}
        >
          {button}
        </Button>
      </Container>
    </Grid>
  );
}
