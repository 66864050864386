import { Box, Typography } from '@mui/material';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const truncateString = (string, maxLength) => {
  let truncatedString = string.slice(0, maxLength);
  if (string.length > maxLength) truncatedString += '...';

  return truncatedString;
};

const imageBoxStyles = (image) => ({
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '350px',
  borderRadius: '10px'
});

function Blog({ id, image, title, description, author, date }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLaptop = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/blogs/${id}`);
  };

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={() => handleNavigate(id)}>
      <Box sx={imageBoxStyles(image)}></Box>
      <Typography
        sx={{
          fontFamily: 'Urbanist',
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: '24px',
          my: 2,
          minHeight: isMobile ? 'unset' : 50
        }}>
        {truncateString(title, isMobile ? 150 : isLaptop ? 70 : 75)}
      </Typography>

      <Typography
        variant="body"
        dangerouslySetInnerHTML={{ __html: truncateString(description, 175) }}
      />
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '20px'
        }}
        style={{ color: theme.palette.primary.main }}>
        {author} • {date}
      </Typography>
    </Box>
  );
}

export default Blog;
