import React, { useState } from 'react';
import { Button, Grid, ListItemText, MenuItem, Typography } from '@mui/material';
import { COLORS } from '../../../../utils/colors';
import { TextInput } from '../../../../components/form/TextField';
import 'react-phone-input-material-ui/lib/style.css';
import { validationSchema3 } from './step3.scheme';
import {
  CURRENCY,
  PLAN_RENEW_MODE,
  PLAN_TYPES,
  PLAN_UPADTE_MODE,
  SESSION_STORAGE_CLIENT,
  SESSION_STORAGE_PLAN_ID
} from '../../../../utils/constants';
import { DateInput } from '../../../../components/form/DateInput';
import { ReactComponent as RemoveIcon } from '../../../../assets/ic-remove.svg';
import { getStep3 } from '../../wizard.api';
import { useQuery } from 'react-query';

import { Select } from '../../../../components/form/Select';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionButtonsContainer, AddChildButtonStyles, CenterFormGrid } from '../../Wizard.styles';
import animationData from '../../../../static/loader.json';
import LottieAnimation from '../../../../components/LottieAnimation';
import moment from 'moment';
import _ from 'lodash';
import { checkSessionStorageItem } from '../../../../utils/sessionStorage';
import { calculateAgeLimit } from '../../../../utils/wizard';
import { GOOGLE_ANALYTICS_LABELS, trackPageview } from '../../../../utils/analytics';
import useTrackScrollDepth from '../../../../hooks/useTrackScrollDepth';
import { CHILDREN } from '../../constants';

const Step3 = ({
  handleSubmit,
  formik,
  onPreviousClick,
  plans,
  selectedPlan,
  ageLimit,
  refetchAccordionDetails,
  countries,
  mode,
  userHasBoughtPetPlan,
  numberOfPetsBought,
  userHasBoughtExpatriatePlan,
  userNumberOfChildren
}) => {
  const navigate = useNavigate();
  const { id, client: clientParam } = useParams();

  const isUserInUpdateMode = mode === PLAN_UPADTE_MODE;
  const isUserInRenewMode = mode === PLAN_RENEW_MODE;

  React.useEffect(() => {
    if (clientParam) {
      sessionStorage.setItem(SESSION_STORAGE_CLIENT, clientParam);
    }
  }, [clientParam]);

  const { min_age, max_age } = ageLimit || {};

  const [client] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);
  const { minBirthDate, maxBirthDate } = calculateAgeLimit(max_age, min_age);

  const [stepError, setStepError] = useState('');
  const hasError = !_.isEmpty(formik.errors);

  const {
    data: step3Data,
    isLoading: step3IsLoading,
    error: step3Error
  } = useQuery(['getStep3', client, selectedPlan], () => getStep3(selectedPlan, mode));

  const {
    has_expat_services,
    has_pet_services,
    expat_service,
    pet_services,
    pet_types,
    pet_gender,
    max_child,
    child_min_age,
    child_max_age,
    single_pet_price
  } = step3Data || {};

  const { minBirthDate: childMinBirthDate, maxBirthDate: childMaxBirthDate } = calculateAgeLimit(
    child_max_age,
    child_min_age
  );

  useEffect(() => {
    trackPageview(GOOGLE_ANALYTICS_LABELS.wizardStep3);
  }, []);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.wizardStep3.name);

  const handleSubmitThirdStep = async (values) => {
    if (formik.isValid) {
      handleSubmit(values);
      setStepError(false);
    } else {
      const touchedFields = Object.keys(validationSchema3.fields).reduce((acc, key) => {
        acc[key] = true;

        return acc;
      }, {});

      setStepError(true);
      formik.setTouched(touchedFields);
      window.scrollTo(0, 130);
    }
  };

  useEffect(() => {
    formik.setFieldValue('plan', { id: id, name: selectedPlan });
  }, []);

  const handlePetPlanChange = (event) => {
    const selectedPetPlan = pet_services?.find((plan) => plan.id === event.target.value);

    if (selectedPetPlan) {
      const { id, nb_of_pets, name, price } = selectedPetPlan;
      const currentPetCount = formik.values.pets?.length;
      const numberOfPets = parseInt(nb_of_pets);

      if (currentPetCount < numberOfPets) {
        const additionalPetsCount = numberOfPets - currentPetCount;

        const updatedPets = [
          ...formik.values.pets,
          ...Array.from({ length: additionalPetsCount }, () => ({
            type: '',
            breed: '',
            gender: '',
            age: '',
            name: ''
          }))
        ];

        formik.setFieldValue('pets', updatedPets);
      } else if (currentPetCount > numberOfPets) {
        const updatedPets = formik.values.pets.slice(0, numberOfPets);
        formik.setFieldValue('pets', updatedPets);
      }

      return formik.setFieldValue('petPlan', { id, name: name, price });
    }

    formik.setFieldValue('petPlan', '');
    formik.setFieldValue('pets', []);
  };

  const renderPetSection = () =>
    has_pet_services && (
      <Grid mb={3} mt={5}>
        <Typography variant="body" fontWeight={700} color={COLORS.black}>
          Pet Services
        </Typography>
        <Grid>
          <Typography variant="smallText">Optional</Typography>
        </Grid>
        <Grid item mt={2}>
          <Select
            label="Pet Plan"
            title="Pet Plan"
            variant="filled"
            name="petPlan"
            id="petPlan"
            fullWidth
            disableUnderline
            onBlur={formik.handleBlur}
            onChange={handlePetPlanChange}
            value={formik.values.petPlan?.id || ''}
            error={formik?.touched?.petPlan && Boolean(formik?.errors?.petPlan)}
            helperText={formik?.touched?.petPlan && formik?.errors?.petPlan}
            renderValue={(selected) => {
              const selectedPetPlan = pet_services?.find((type) => type.id === selected);

              const option = selectedPetPlan
                ? isUserInUpdateMode
                  ? selectedPetPlan.name
                  : `${selectedPetPlan.name} (Add $${selectedPetPlan.price})`
                : '';

              return <div>{option}</div>;
            }}>
            <MenuItem
              key={99}
              value={{ id: 0, nb_of_pets: 0 }}
              disabled={isUserInUpdateMode && userHasBoughtPetPlan}>
              <ListItemText primary={`None`} />
            </MenuItem>
            {pet_services?.map(({ id, name, price, nb_of_pets }, index) => {
              const option = isUserInUpdateMode ? `${name}` : `${name} (Add $${price})`;

              return (
                <MenuItem
                  key={index}
                  value={id}
                  disabled={
                    isUserInUpdateMode && userHasBoughtPetPlan && nb_of_pets < numberOfPetsBought
                  }>
                  <ListItemText primary={option} />
                </MenuItem>
              );
            })}
          </Select>
          {isUserInUpdateMode && (
            <Typography variant="smallText" color="#ABAFB1">
              Every new pet added will cost extra ${single_pet_price}
            </Typography>
          )}
        </Grid>
        <Grid mt={2}>
          {formik.values.pets?.map((pet, index) => {
            return (
              <Grid mt={2} key={index}>
                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="body" fontWeight={500} color={COLORS.black}>
                    Pet {index + 1}
                  </Typography>
                </Grid>
                <Grid display={'flex'} gap={1} mt={1}>
                  <Grid flex={1}>
                    <Select
                      fullWidth
                      label="Pet Type"
                      id={`pets[${index}].type`}
                      name={`pets[${index}].type`}
                      value={pet.type?.id || ''}
                      onChange={(event) => {
                        const selectedPetType = pet_types?.find(
                          (type) => type.id === event.target.value
                        );

                        formik.handleChange({
                          target: {
                            name: `pets[${index}].type`,
                            value: selectedPetType
                          }
                        });
                      }}
                      renderValue={(selected) => {
                        const selectedPetType = pet_types?.find((type) => type.id === selected);

                        return selectedPetType.name;
                      }}
                      error={formik?.touched?.pets && formik?.errors?.pets?.[index]?.type?.name}
                      helperText={
                        formik?.touched?.pets && formik?.errors?.pets?.[index]?.type?.name
                      }>
                      {pet_types?.map(({ id, name }, index) => (
                        <MenuItem key={index} value={id}>
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid flex={1}>
                    <TextInput
                      fullWidth
                      label="Breed"
                      id={`pets[${index}].breed`}
                      name={`pets[${index}].breed`}
                      value={pet.breed ?? ''}
                      onChange={formik.handleChange}
                      error={
                        formik?.touched?.pets?.[index]?.breed &&
                        formik?.errors?.pets?.[index]?.breed
                      }
                      helperText={
                        formik?.touched?.pets?.[index]?.breed &&
                        formik?.errors?.pets?.[index]?.breed
                      }
                    />
                  </Grid>
                  <Grid flex={1}>
                    <Select
                      label="Pet Gender"
                      title="Pet Gender"
                      variant="filled"
                      id={`pets[${index}].gender`}
                      name={`pets[${index}].gender`}
                      fullWidth
                      disableUnderline
                      onBlur={formik.handleBlur}
                      value={pet.gender?.id || ''}
                      error={formik?.errors?.pets?.[index]?.gender}
                      helperText={formik?.errors?.pets?.[index]?.gender}
                      onChange={(event) => {
                        const selectedPetGender = pet_gender?.find(
                          (gender) => gender.id === event.target.value
                        );

                        formik.handleChange({
                          target: {
                            name: `pets[${index}].gender`,
                            value: selectedPetGender
                          }
                        });
                      }}
                      renderValue={(selected) => {
                        const selectedPetGender = pet_gender?.find(
                          (gender) => gender.id === selected
                        );

                        return selectedPetGender.name;
                      }}>
                      {pet_gender.map(({ id, name }, index) => (
                        <MenuItem key={index} value={id}>
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid display={'flex'} gap={1} mt={1}>
                  <Grid flex={1}>
                    <TextInput
                      fullWidth
                      label="Pet Age"
                      id={`pets[${index}].age`}
                      name={`pets[${index}].age`}
                      value={pet.age ?? ''}
                      onChange={formik.handleChange}
                      error={formik?.errors?.pets?.[index]?.age}
                      helperText={formik?.errors?.pets?.[index]?.age}
                      inputProps={{ type: 'number', min: 0 }}
                    />
                  </Grid>
                  <Grid flex={1}>
                    <TextInput
                      fullWidth
                      onBlur={formik.handleBlur}
                      label="Pet Name"
                      id={`pets[${index}].name`}
                      name={`pets[${index}].name`}
                      value={pet.name ?? ''}
                      onChange={formik.handleChange}
                      error={formik?.errors?.pets?.[index]?.name}
                      helperText={formik?.errors?.pets?.[index]?.name}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );

  const renderFormActionButtons = () => (
    <ActionButtonsContainer mt={7} container gap={1}>
      <Button variant="outlined" onClick={onPreviousClick}>
        Previous
      </Button>
      <Button variant="contained" type="submit" onClick={handleSubmitThirdStep}>
        Next
      </Button>
    </ActionButtonsContainer>
  );

  const handleExpatChange = (event) => {
    const selectedPlan = expat_service?.find((plan) => plan.id === event.target.value);
    if (selectedPlan)
      return formik.setFieldValue('expatriatePlan', {
        id: selectedPlan.id,
        name: `${_.capitalize(PLAN_TYPES[formik.values.plan.name?.toUpperCase()])} Coverage `
      });

    return formik.setValues({
      ...formik.values,
      expatriatePlan: '',
      travelDateFrom: '',
      travelDateTo: '',
      travelLocation: ''
    });
  };

  const renderExpatriateSection = () =>
    has_expat_services && (
      <Grid mb={3} mt={5}>
        <Typography variant="body" fontWeight={700} color={COLORS.black}>
          Expatriate Services
        </Typography>
        <Grid>
          <Typography variant="smallText">Optional</Typography>
        </Grid>
        <Grid mt={2}>
          <Select
            fullWidth
            label="Plan"
            id="expatriatePlan"
            onBlur={formik.handleBlur}
            name="expatriatePlan"
            value={formik.values.expatriatePlan?.id || ''}
            onChange={(event) => handleExpatChange(event)}
            error={formik?.touched?.expatriatePlan && Boolean(formik?.errors?.expatriatePlan)}
            helperText={formik?.touched?.expatriatePlan && formik?.errors?.expatriatePlan}
            renderValue={(selected) => {
              const selectedPlan = expat_service?.find((plan) => plan?.id === selected);

              return (
                <div>{`${_.capitalize(
                  PLAN_TYPES[formik.values.plan.name?.toUpperCase()]
                )} Coverage (${CURRENCY}${selectedPlan?.price})`}</div>
              );
            }}>
            <MenuItem
              key={99}
              value={''}
              disabled={userHasBoughtExpatriatePlan && isUserInUpdateMode}>
              <ListItemText primary={`None`} />
            </MenuItem>
            {expat_service?.map(({ id, price }, index) => (
              <MenuItem key={index} value={id}>
                <ListItemText
                  primary={`${_.capitalize(
                    PLAN_TYPES[formik.values.plan?.name?.toUpperCase()]
                  )} Coverage (${CURRENCY}${price})`}
                />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {formik.values.expatriatePlan?.id && (
          <>
            <Grid mt={2}>
              <Select
                fullWidth
                label="Travel Location"
                id="travelLocation"
                onBlur={formik.handleBlur}
                name="travelLocation"
                defaultValue=""
                value={formik.values.travelLocation?.id ?? ''}
                onChange={(event) => {
                  const countrySelected = countries.find(
                    (country) => country.tid === event.target.value
                  );

                  const country = { name: countrySelected.name, id: countrySelected.tid };

                  formik.setFieldValue('travelLocation', country);
                }}
                error={
                  formik?.touched?.travelLocation?.name &&
                  Boolean(formik?.errors?.travelLocation?.name)
                }
                helperText={
                  formik?.touched?.travelLocation?.name && formik?.errors?.travelLocation?.name
                }
                renderValue={(selected) => {
                  const selectedCountry = countries.find((country) => country.tid === selected);

                  return <div>{selectedCountry ? `${selectedCountry?.name} ` : ''}</div>;
                }}>
                {countries?.map(({ name, tid }, index) => (
                  <MenuItem key={index} value={tid}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid mt={2} mb={3} display={'flex'} gap={1}>
              <Grid flex={1}>
                <DateInput
                  fullWidth
                  onBlur={formik.handleBlur}
                  label="Travel Date From"
                  id="travelDateFrom"
                  name="travelDateFrom"
                  value={formik.values.travelDateFrom ?? ''}
                  onChange={(date) => formik.setFieldValue('travelDateFrom', date)}
                  error={formik?.touched?.travelDateFrom && Boolean(formik?.errors?.travelDateFrom)}
                  helperText={formik?.touched?.travelDateFrom && formik?.errors?.travelDateFrom}
                  maxDate={
                    formik?.values?.travelDateTo
                      ? moment(formik?.values?.travelDateTo) ?? null
                      : null
                  }
                  disablePast
                />
              </Grid>
              <Grid flex={1}>
                <DateInput
                  fullWidth
                  onBlur={formik.handleBlur}
                  label="Travel Date To"
                  id="travelDateTo"
                  name="travelDateTo"
                  value={formik.values.travelDateTo ?? ''}
                  onChange={(date) => formik.setFieldValue('travelDateTo', date)}
                  error={formik?.touched?.travelDateTo && Boolean(formik?.errors?.travelDateTo)}
                  helperText={formik?.touched?.travelDateTo && formik?.errors?.travelDateTo}
                  minDate={
                    formik?.values?.travelDateFrom
                      ? moment(formik?.values?.travelDateFrom) ?? null
                      : null
                  }
                  disablePast
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );

  const renderDualPlanSection = () =>
    formik.values.plan?.name?.toLowerCase() === PLAN_TYPES.DUAL && (
      <Grid mt={2}>
        <Grid border={'1px solid #CFD3D4'} borderRadius={'8px'} px={3} py={2}>
          <Typography variant="body" fontWeight={500} color={COLORS.black}>
            Second Participant
          </Typography>
          <Grid display={'flex'} gap={1} mt={1}>
            <Grid flex={1}>
              <TextInput
                disabled={isUserInUpdateMode}
                fullWidth
                label="First Name"
                id="spouseFirstName"
                name="spouseFirstName"
                value={formik.values.spouseFirstName}
                onChange={formik.handleChange}
                error={formik?.touched?.spouseFirstName && Boolean(formik?.errors?.spouseFirstName)}
                helperText={formik?.touched?.spouseFirstName && formik?.errors?.spouseFirstName}
              />
            </Grid>
            <Grid flex={1}>
              <TextInput
                disabled={isUserInUpdateMode}
                fullWidth
                label="Last Name"
                id="spouseLastName"
                name="spouseLastName"
                value={formik.values.spouseLastName}
                onChange={formik.handleChange}
                error={formik?.touched?.spouseLastName && Boolean(formik?.errors?.spouseLastName)}
                helperText={formik?.touched?.spouseLastName && formik?.errors?.spouseLastName}
              />
            </Grid>
          </Grid>
          <Grid display={'flex'} gap={1} mt={1}>
            <Grid flex={1}>
              <TextInput
                fullWidth
                label="MI (Optional)"
                id="spouseMi"
                name="spouseMi"
                value={formik.values.spouseMi}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid flex={1}>
              <DateInput
                disabled={isUserInUpdateMode}
                fullWidth
                disableFuture
                onBlur={formik.handleBlur}
                label="Birth Date"
                id="spouseBirthdate"
                name="spouseBirthdate"
                value={formik.values.spouseBirthdate}
                onChange={(date) => formik.setFieldValue('spouseBirthdate', date)}
                error={formik?.touched?.spouseBirthdate && Boolean(formik?.errors?.spouseBirthdate)}
                helperText={formik?.touched?.spouseBirthdate && formik?.errors?.spouseBirthdate}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  const renderFamilyPlanSection = () =>
    formik.values.plan?.name?.toLowerCase() === PLAN_TYPES.FAMILY && (
      <Grid mt={2}>
        <Grid border={'1px solid #CFD3D4'} borderRadius={'8px'} px={3} py={2}>
          <Typography variant="body" fontWeight={500} color={COLORS.black}>
            Spouse
          </Typography>
          <Grid display={'flex'} gap={1} mt={1}>
            <Grid flex={1}>
              <TextInput
                disabled={isUserInUpdateMode}
                fullWidth
                label="First Name"
                id="spouseFirstName"
                name="spouseFirstName"
                value={formik.values.spouseFirstName ?? ''}
                onChange={formik.handleChange}
                error={formik?.touched?.spouseFirstName && Boolean(formik?.errors?.spouseFirstName)}
                helperText={formik?.touched?.spouseFirstName && formik?.errors?.spouseFirstName}
              />
            </Grid>
            <Grid flex={1}>
              <TextInput
                disabled={isUserInUpdateMode}
                fullWidth
                label="Last Name"
                id="spouseLastName"
                name="spouseLastName"
                value={formik.values.spouseLastName ?? ''}
                onChange={formik.handleChange}
                error={formik?.touched?.spouseLastName && Boolean(formik?.errors?.spouseLastName)}
                helperText={formik?.touched?.spouseLastName && formik?.errors?.spouseLastName}
              />
            </Grid>
          </Grid>
          <Grid display={'flex'} gap={1} mt={1}>
            <Grid flex={1}>
              <TextInput
                fullWidth
                label="MI (Optional)"
                id="spouseMi"
                name="spouseMi"
                value={formik.values.spouseMi ?? ''}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid flex={1}>
              <DateInput
                disabled={isUserInUpdateMode}
                fullWidth
                onBlur={formik.handleBlur}
                label="Birth Date"
                id="spouseBirthdate"
                name="spouseBirthdate"
                value={formik.values.spouseBirthdate ?? ''}
                onChange={(date) => formik.setFieldValue('spouseBirthdate', date)}
                error={formik?.touched?.spouseBirthdate && Boolean(formik?.errors?.spouseBirthdate)}
                helperText={
                  Boolean(formik?.touched?.spouseBirthdate) && formik?.errors?.spouseBirthdate
                }
                minDate={minBirthDate}
                maxDate={maxBirthDate}
              />
            </Grid>
          </Grid>
          {formik.values.children?.map((child, index) => {
            const showRemoveIcon =
              formik.values.children?.length > 1 &&
              (isUserInUpdateMode ? index >= userNumberOfChildren : true);

            const disableChildField = isUserInUpdateMode && index < userNumberOfChildren;

            return (
              <Grid mt={2} key={index}>
                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="body" fontWeight={500} color={COLORS.black}>
                    Child {index + 1}
                  </Typography>

                  {showRemoveIcon && (
                    <RemoveIcon
                      onClick={() => {
                        const updatedChildren = formik.values.children.filter(
                          (_, idx) => idx !== index
                        );

                        formik.setFieldValue('children', updatedChildren);
                      }}
                    />
                  )}
                </Grid>
                <Grid display={'flex'} gap={1} mt={1}>
                  <Grid flex={1}>
                    <TextInput
                      disabled={disableChildField}
                      fullWidth
                      label="First Name"
                      onBlur={formik.handleBlur}
                      id={`children[${index}].firstName`}
                      name={`children[${index}].firstName`}
                      value={child?.firstName ?? ''}
                      onChange={formik.handleChange}
                      error={
                        formik?.touched?.children && formik.errors?.children?.[index]?.firstName
                      }
                      helperText={
                        formik?.touched?.children && formik.errors?.children?.[index]?.firstName
                      }
                    />
                  </Grid>
                  <Grid flex={1}>
                    <TextInput
                      disabled={disableChildField}
                      fullWidth
                      label="Last Name"
                      onBlur={formik.handleBlur}
                      id={`children[${index}].lastName`}
                      name={`children[${index}].lastName`}
                      value={child.lastName ?? ''}
                      onChange={formik.handleChange}
                      helperText={
                        formik?.touched?.children && formik.errors?.children?.[index]?.lastName
                      }
                    />
                  </Grid>
                </Grid>
                <Grid display={'flex'} gap={1} mt={1}>
                  <Grid flex={1}>
                    <TextInput
                      fullWidth
                      onBlur={formik.handleBlur}
                      label="MI (Optional)"
                      id={`children[${index}].mi`}
                      name={`children[${index}].mi`}
                      value={child.mi ?? ''}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid flex={1}>
                    <DateInput
                      disabled={disableChildField}
                      fullWidth
                      disableFuture
                      onBlur={formik.handleBlur}
                      label="Birth Date"
                      id={`children[${index}].birthdate`}
                      name={`children[${index}].birthdate`}
                      value={child.birthdate ?? ''}
                      onChange={(date) =>
                        formik.setFieldValue(`children[${index}].birthdate`, date)
                      }
                      error={
                        formik?.touched?.children && formik?.errors?.children?.[index]?.birthdate
                      }
                      helperText={
                        formik?.touched?.children && formik?.errors?.children?.[index]?.birthdate
                      }
                      minDate={childMinBirthDate}
                      maxDate={childMaxBirthDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          {formik.values.children?.length < max_child && (
            <AddChildButtonStyles
              onClick={() =>
                formik.setFieldValue('children', [...formik.values.children, CHILDREN])
              }>
              Add Child
            </AddChildButtonStyles>
          )}
        </Grid>
      </Grid>
    );

  const handlePlanChange = (event) => {
    const selectedPlan = plans?.find((plan) => plan.id === event.target.value);
    formik.setFieldValue('plan', { id: selectedPlan?.id, name: selectedPlan?.plan });
    const isFamilyPlan = selectedPlan?.plan?.toLowerCase() === PLAN_TYPES.FAMILY;

    isFamilyPlan
      ? formik.setFieldValue('children', [CHILDREN])
      : formik.setFieldValue('children', []);

    const url =
      (clientParam ? `/${clientParam}` : '') +
      `/plan/${selectedPlan?.id}` +
      (isUserInRenewMode ? '/renew' : '');

    if (isUserInRenewMode) {
      sessionStorage.setItem(SESSION_STORAGE_PLAN_ID, selectedPlan?.id);
      formik.setFieldValue('expatriatePlan', '');
      formik.setFieldValue('travelLocation', '');
      formik.setFieldValue('travelDateFrom', '');
      formik.setFieldValue('travelDateTo', '');
      formik.setFieldValue('pets', []);
      formik.setFieldValue('petPlan', '');
      formik.setFieldValue('children', [CHILDREN]);
      formik.setFieldValue('spouseFirstName', '');
      formik.setFieldValue('spouseLastName', '');
      formik.setFieldValue('spouseMi', '');
      formik.setFieldValue('spouseBirthdate', '');
    }

    navigate(url);
    refetchAccordionDetails();
  };

  const renderplanSelect = () => (
    <Grid>
      <Grid mb={3}>
        <Typography variant="body" fontWeight={700} color={COLORS.black}>
          Plan Type
        </Typography>
      </Grid>
      <Grid mt={2}>
        <Select
          disabled={isUserInUpdateMode}
          name="plan"
          label={'Plan Type'}
          fullWidth
          disableUnderline
          onBlur={formik.handleBlur}
          onChange={(event) => {
            handlePlanChange(event);
          }}
          value={formik.values.plan?.id || ''}
          error={formik?.touched?.plan && Boolean(formik?.errors?.plan)}
          helperText={formik?.touched?.plan && formik?.errors?.plan}
          renderValue={(selected) => {
            const selectedPlan = plans?.find((plan) => plan?.id === selected);

            return <div>{`${selectedPlan?.plan} Coverage (${selectedPlan?.price})`}</div>;
          }}>
          {plans?.map(({ id, plan, price }, index) => (
            <MenuItem key={index} value={id}>
              <ListItemText primary={`${plan} Coverage (${price})`} />
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );

  const renderStepError = () =>
    hasError &&
    stepError && (
      <Grid mb={0.8}>
        <Typography variant="body" fontWeight={500} color={COLORS.error}>
          Please ensure all fields are filled in before proceeding.
        </Typography>
      </Grid>
    );

  return (
    <Grid flexDirection={'column'} alignItems={'center'} justifyContent={'center'} display={'flex'}>
      <CenterFormGrid>
        {step3IsLoading && !step3Error && (
          <Grid width={'30%'} ml={15}>
            <LottieAnimation animationData={animationData} looping={true} />
          </Grid>
        )}
        {!step3IsLoading && !step3Error && (
          <>
            <Grid mb={3}>{renderStepError()}</Grid>
            {renderplanSelect()}
            {renderDualPlanSection()}
            {renderFamilyPlanSection()}
            {renderExpatriateSection()}
            {renderPetSection()}
            {renderFormActionButtons()}
          </>
        )}
      </CenterFormGrid>
    </Grid>
  );
};

export default Step3;
