import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const Iframe = ({ src, title, onLoad }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  let height;

  if (isMobile) {
    height = '960px';
  } else if (isTablet) {
    height = '620px';
  } else {
    height = '600px';
  }

  return (
    <Box
      component="iframe"
      src={src}
      title={title}
      width={'100%'}
      height={height}
      border={'none'}
      pb={5}
      onLoad={onLoad}
    />
  );
};

export default Iframe;
