import * as Yup from 'yup';
import { PLAN_TYPES } from '../../../../utils/constants';

export const validationSchema3 = Yup.object({
  plan: Yup.object({
    id: Yup.string(),
    name: Yup.string()
  }),
  spouseFirstName: Yup.string().when('plan', (plan) => {
    const planType = plan?.[0]?.name?.toLowerCase();

    if (planType === PLAN_TYPES.DUAL || planType === PLAN_TYPES.FAMILY) {
      return Yup.string()
        .trim()
        .min(2, 'First Name must be minimum 2 characters')
        .max(30, 'First Name must not be more than 30 characters')
        .required('First Name is required');
    }

    return Yup.string();
  }),
  spouseLastName: Yup.string().when('plan', (plan, schema) => {
    const planType = plan?.[0]?.name?.toLowerCase();

    if (planType === PLAN_TYPES.DUAL || planType === PLAN_TYPES.FAMILY) {
      return schema
        .trim()
        .min(2, 'Last Name must be minimum 2 characters')
        .max(30, 'Last Name must not be more than 30 characters')
        .required('Last Name is required');
    }

    return Yup.string();
  }),

  spouseMi: Yup.string(),
  spouseBirthdate: Yup.string().when('plan', (plan, schema) => {
    const planType = plan?.[0]?.name?.toLowerCase();

    if (planType === PLAN_TYPES.DUAL || planType === PLAN_TYPES.FAMILY) {
      return schema
        .trim()
        .min(2, 'Birth Date must be minimum 2 characters')
        .max(30, 'Birth Date must not be more than 30 characters')
        .required('Birth Date is required');
    }

    return schema;
  }),

  children: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().when('$plan', (plan) => {
        const planType = plan?.[0]?.name?.toLowerCase();

        if (planType === PLAN_TYPES.FAMILY) {
          return Yup.string()
            .trim()

            .min(2, 'Child First Name must be minimum 2 characters')
            .max(30, 'Child First Name must not be more than 30 characters')
            .required('Child First Name is required');
        }

        return Yup.string();
      }),
      lastName: Yup.string().when('$plan', (plan) => {
        const planType = plan?.[0]?.name?.toLowerCase();

        if (planType === PLAN_TYPES.FAMILY) {
          return Yup.string()
            .trim()

            .min(2, 'Child Last Name must be minimum 2 characters')
            .max(30, 'Child Last Name must not be more than 30 characters')
            .required('Child Last Name is required');
        }

        return Yup.string();
      }),
      mi: Yup.string(),
      birthdate: Yup.string().when('$plan', (plan) => {
        const planType = plan?.[0]?.name?.toLowerCase();

        if (planType === PLAN_TYPES.FAMILY) {
          return Yup.string()
            .trim()

            .min(2, 'Child Birth Date must be minimum 2 characters')
            .max(30, 'Child Birth Date must not be more than 30 characters')
            .required('Child Birth Date is required');
        }

        return Yup.string();
      })
    })
  ),
  expatriatePlan: Yup.object({
    id: Yup.string(),
    name: Yup.string()
  }),

  travelLocation: Yup.object({
    name: Yup.string(),
    id: Yup.string()
  }),
  travelDateFrom: Yup.date(),
  travelDateTo: Yup.date(),
  petPlan: Yup.object({
    id: Yup.string(),
    name: Yup.string(),
    pice: Yup.string()
  }),
  pets: Yup.array().of(
    Yup.object().shape({
      type: Yup.object().shape({
        id: Yup.string(),
        name: Yup.string().when('$petPlan', (petPlan) => {
          if (petPlan !== '') {
            return Yup.string()
              .trim()
              .min(2, 'Pet Type must be minimum 2 characters')
              .max(30, 'Pet Type must not be more than 30 characters')
              .required('Pet Type is required');
          }

          return Yup.string();
        })
      }),
      breed: Yup.string()
        .nullable(true)
        .transform((value, originalValue) => {
          if (originalValue === '') return null;

          return value;
        }),
      gender: Yup.object({
        id: Yup.string(),
        name: Yup.string()
      }),
      age: Yup.number()
        .nullable(true)
        .transform((value, originalValue) => {
          if (originalValue === '') return null;

          return value;
        }),
      name: Yup.string()
        .nullable(true)
        .transform((value, originalValue) => {
          if (originalValue === '') return null;

          return value;
        })
    })
  )
});
