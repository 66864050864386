import axios from 'axios';
import { checkSessionStorageItem } from '../../utils/sessionStorage';
import { SESSION_STORAGE_CLIENT } from '../../utils/constants';

export const banner = async () => {
  const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);
  const generalApi = 'homepage-banner';

  const apiUrl = generalApi + (hasClient ? `?client=${client}` : '');

  const response = await axios.get(apiUrl);

  return response?.data;
};

export const services = async () => {
  const response = await axios.get('services');

  return response?.data;
};

export const features = async () => {
  const response = await axios.get('features');

  return response?.data;
};

export const plans = async () => {
  const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);
  const generalApi = 'plans';

  const apiUrl = generalApi + (hasClient ? `?client=${client}` : '');

  const response = await axios.get(apiUrl);

  return response?.data;
};

export const blogs = async () => {
  const response = await axios.get('blogs');

  return response?.data;
};

export const pet = async () => {
  const response = await axios.get('homepage-pet');

  return response?.data;
};

export const feedbacks = async () => {
  const response = await axios.get('feedbacks');

  return response?.data;
};
