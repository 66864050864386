import { Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Plan from '../Plan';
import { checkSessionStorageItem } from '../../utils/sessionStorage';
import { SESSION_STORAGE_CLIENT } from '../../utils/constants';

export default function Plans({ title, description, list, button }) {
  const isLoggedIn = sessionStorage.getItem('isLoggedIn');
  const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);

  const navigateTo = hasClient ? `${client}/plans` : `/plans`;

  return (
    <Grid
      p={{ md: 5, xs: 0 }}
      py={{ xs: 3 }}
      sx={{
        backgroundImage: 'url("/images/green-bg.svg")',
        backgroundSize: 'cover'
      }}>
      <Container align="center" maxWidth="xl">
        <Grid align="center" p={{ md: 5, xs: 0 }}>
          <Typography
            variant="heading2"
            sx={{
              mb: 1
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Typography variant="subtitle" dangerouslySetInnerHTML={{ __html: description }} />
        </Grid>
        <Grid container gap={3} align="center" justifyContent="center" mb={isLoggedIn ? 5 : 0}>
          {list?.length > 0 &&
            list?.map((plan) => (
              <Grid item xl={3.5} sm={8} key={plan.id}  mb={2} >
                <Plan
                  name={plan.plan}
                  description={plan.text}
                  price={plan.price}
                  hideStartButton={true}
                />
              </Grid>
            ))}
        </Grid>
        {!isLoggedIn && (
          <Button
            variant="contained"
            href={navigateTo}
            sx={{
              color: '#fff',
              textTransform: 'none',
              my: 5
            }}>
            {button}
          </Button>
        )}
      </Container>
    </Grid>
  );
}
