import { Container, Grid, Typography, Box, Button } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function Pet({ title, description, button, image }) {

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm","md"));


  const imageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: isTablet? "80%" :"83%",
    height: isTablet? "150px" :"165px",
    position: "absolute",
    bottom: isTablet? 30 : 20,
    right: isTablet? 35 : 40,
  });

  const backgroundImageBoxStyles = {
    backgroundImage: `url("/images/hp-pet-bg.svg")`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    height: "250px",
    position: "relative",
  };

  return (
    <Grid py={5} sx={{ backgroundColor: "#EBF4F4" }}>
      <Container maxWidth="xl">
        <Grid container  columnSpacing={{ md: 3, sm: 3, xs: 0 }}>
          <Grid item md={4} sm={4} xs={12}>
            <Box sx={backgroundImageBoxStyles}>
              <Box sx={imageBoxStyles(image)}></Box>
            </Box>
          </Grid>
          <Grid item md={8} sm={8} xs={12} pt={{ md: 7, sm: 7, xs: 3 }}>
            <Typography
              variant="heading2"
              sx={{
                mb: 1,
              }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <Typography
              variant="subtitle"
              paragraph={true}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Button
              variant="contained"
              href="/services/pet-assistance"
              sx={{
                color: "#fff",
                textTransform: "none",
              }}
            >
              {button}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
