import { getTravelInformation } from './APIRequests.js';

import { Container, Grid, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import Iframe from '../../components/Iframe.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import ServerError from '../Static/ServerError.js';
import PageLoader from '../../components/PageLoader.js';

export default function TravelInformation() {
  const {
    data,
    isLoading: isDataLoading,
    error
  } = useQuery('getTravelInformation', getTravelInformation);

  const [isIframeLoading, setIsIframeLoading] = useState(true);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.travelInformation.name);

  const handleIframeLoad = () => {
    setIsIframeLoading(false);
  };

  return (
    <Fragment>
      {(isDataLoading || isIframeLoading) && <PageLoader />}
      {!isDataLoading && !isIframeLoading && error && <ServerError />}
      <Container maxWidth="xl">
        <Grid pt={12} align="center">
          <>
            <Typography variant="heading1">{data?.page_data.title}</Typography>
            <Typography
              variant="subtitle"
              paragraph={true}
              dangerouslySetInnerHTML={{
                __html: data?.page_data.subtitle
              }}
              mt={3}
            />
            <Iframe
              src={process.env.REACT_APP_COUNTRY_WATCH_URL}
              title="Country Watch GTP"
              onLoad={handleIframeLoad}
            />
          </>
        </Grid>
      </Container>
    </Fragment>
  );
}
