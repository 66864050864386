// libs
import React from "react";

// mui
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

// util
import { MOMENT_DATE_FORMAT } from "../../utils/date";
import moment from "moment";

import { FormHelperText } from "@mui/material";

export function DateInput(props) {
  const { errors, label, value, onChange, helperText, ...otherProps } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
       views={['year', 'month', 'day']}
        slots={{
          openPickerIcon: CalendarMonthOutlinedIcon,
        }}
        slotProps={{
          textField: {
            variant: "filled",
            InputProps: { disableUnderline: true },
            InputLabelProps: { shrink: true },
            inputProps: { readOnly: true },
          },
          actionBar: {
            actions: ["clear"],
          },
        }}
        sx={{ width: "100%" }}
        {...otherProps}
        inputFormat={MOMENT_DATE_FORMAT}
        label={label}
        value={value ? moment(value, MOMENT_DATE_FORMAT) : null}
        onChange={(date) => onChange(date?.format(MOMENT_DATE_FORMAT))}
        closeOnSelect
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </LocalizationProvider>
  );
}
